import React from 'react';
import { Box } from 'layout';
import { Text } from 'components/typo';
import {
  PRODUCT_QUANTITYS_SHORT,
  QUANTITYS,
} from 'constants/ProductsTypes';

const normalizeAmount = (num, measure) => {
  const s = String(num);

  if (s.indexOf('.000') > 0) {
    return s
      .replace('.000', '');
  }
  if (s.includes('.') && s[s.length - 1] === '0') {
    return s.substring(0, s.length - 1);
  }
  if (measure !== QUANTITYS.GRAM && measure !== QUANTITYS.KILLOGRAMM && s.indexOf('.00') > 0) {
    return s
      .replace('.00', '.');
  }

  return s;
};

const CardPrice = ({
  rawPrice,
  price,
  amount = null,
  measure,
  isActive,
  isHovered,
}) => (
  <Box jc="flex-end">
    {amount && amount > 0 ? (
      <Text
        color="grey500"
        fw="m"
        tag="span"
      >
        {`${normalizeAmount(amount, measure)}`}
        {measure ? PRODUCT_QUANTITYS_SHORT[measure] || 'x' : 'x'}
        {' '}
      </Text>
    ) : ''}
      &nbsp;
    {rawPrice && (
      <Text
        noPointers
        nowrap
        className="mr-1"
        color="grey500"
        td="line-through"
      >
        {rawPrice}
      </Text>
    )}
    <Text
      noPointers
      nowrap
      transitionOn
      color={isHovered ? 'grey700' : isActive ? 'black' : 'grey500'}
      fw="m"
    >
      {price}
      {' '}
      ₽
    </Text>
  </Box>
);

export default CardPrice;
