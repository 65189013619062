export const SettingsActionTypes = {
  SET_FETCHING_SETTINGS_SUBMITTING: 'SET_FETCHING_SETTINGS_SUBMITTING',
  SET_FETCHING_SETTINGS_DATA: 'SET_FETCHING_SETTINGS_DATA',

  FETCH_COMPANY_UPDATE_SUCCESS: 'FETCH_COMPANY_UPDATE_SUCCESS',
  FETCH_COMPANY_SPLASH_UPDATE_SUCCESS: 'FETCH_COMPANY_SPLASH_UPDATE_SUCCESS',
  FETCH_COMPANY_SPLASH_DELETE_SUCCESS: 'FETCH_COMPANY_SPLASH_DELETE_SUCCESS',
  FETCH_COMPANY_SPLASH_GET_SUCCESS: 'FETCH_COMPANY_SPLASH_GET_SUCCESS',
  FETCH_COMPANY_APP_UPDATE_SUCCESS: 'FETCH_COMPANY_APP_UPDATE_SUCCESS',

  FETCH_SCHEDULE_CREATE_SUCCESS: 'FETCH_SCHEDULE_CREATE_SUCCESS',
  FETCH_SCHEDULE_UPDATE_SUCCESS: 'FETCH_SCHEDULE_UPDATE_SUCCESS',
  FETCH_SCHEDULE_DELETE_SUCCESS: 'FETCH_SCHEDULE_DELETE_SUCCESS',

  FETCH_COMPANY_SOCIAL_LINK_UPDATE_SUCCESS: 'FETCH_COMPANY_SOCIAL_LINK_UPDATE_SUCCESS',

  SET_FETCHING_GET_APP_MENU_OPTIONS_SUCCESS: 'SET_FETCHING_GET_APP_MENU_OPTIONS_SUCCESS',
  FETCH_GET_APP_MENU_OPTIONS_SUCCESS: 'FETCH_GET_APP_MENU_OPTIONS_SUCCESS',
  FETCH_TOGGLE_APP_MENU_OPTION_SUCCESS: 'FETCH_TOGGLE_APP_MENU_OPTION_SUCCESS',

  SET_FETCHING_GET_COMPANY_PAYMENTS: 'SET_FETCHING_GET_COMPANY_PAYMENTS',
  FETCH_GET_COMPANY_PAYMENTS_SUCCESS: 'FETCH_GET_COMPANY_PAYMENTS_SUCCESS',
  FETCH_TOGGLE_COMPANY_PAYMENT_SUCCESS: 'FETCH_TOGGLE_COMPANY_PAYMENT_SUCCESS',

  SET_FETCHING_GET_APP_THEMES: 'SET_FETCHING_GET_APP_THEMES',
  FETCH_GET_APP_THEMES_SUCCESS: 'FETCH_GET_APP_THEMES_SUCCESS',

  SET_FETCHING_GET_COUNTRIES: 'SET_FETCHING_GET_COUNTRIES',
  FETCH_GET_COUNTRIES_SUCCESS: 'FETCH_GET_COUNTRIES_SUCCESS',

  SET_FETCHING_GET_DEVICES_TYPES: 'SET_FETCHING_GET_DEVICES_TYPES',
  FETCH_GET_DEVICES_TYPES_SUCCESS: 'FETCH_GET_DEVICES_TYPES_SUCCESS',
  FETCH_GET_DEVICES_TYPES_ERROR: 'FETCH_GET_DEVICES_TYPES_ERROR',

  SET_FETCHING_GET_SCREENSHOTS: 'SET_FETCHING_GET_SCREENSHOTS',
  FETCH_GET_SCREENSHOTS_SUCCESS: 'FETCH_GET_SCREENSHOTS_SUCCESS',
  FETCH_GET_SCREENSHOTS_ERROR: 'FETCH_GET_SCREENSHOTS_ERROR',

  SET_FETCHING_UPLOAD_SCREENSHOT: 'SET_FETCHING_UPLOAD_SCREENSHOT',
  FETCH_UPLOAD_SCREENSHOT_SUCCESS: 'FETCH_UPLOAD_SCREENSHOT_SUCCESS',
  FETCH_UPLOAD_SCREENSHOT_ERROR: 'FETCH_UPLOAD_SCREENSHOT_ERROR',

  SET_FETCHING_DELETE_SCREENSHOT: 'SET_FETCHING_DELETE_SCREENSHOT',
  FETCH_DELETE_SCREENSHOT_SUCCESS: 'FETCH_DELETE_SCREENSHOT_SUCCESS',
  FETCH_DELETE_SCREENSHOT_ERROR: 'FETCH_DELETE_SCREENSHOT_ERROR',

  FETCH_DELETE_ALL_SCREENSHOTS_BY_TYPE_SUCCESS: 'FETCH_DELETE_ALL_SCREENSHOTS_BY_TYPE_SUCCESS',
  SET_FETCHING_DELETE_ALL_SCREENSHOTS_BY_TYPE: 'SET_FETCHING_DELETE_ALL_SCREENSHOTS_BY_TYPE',
  FETCH_DELETE_ALL_SCREENSHOTS_BY_TYPE_ERROR: 'FETCH_DELETE_ALL_SCREENSHOTS_BY_TYPE_ERROR',

  FETCH_APP_SEND_ON_MODERATION_SUCCESS: 'FETCH_APP_SEND_ON_MODERATION_SUCCESS',
  SET_FETCHING_APP_SEND_ON_MODERATION: 'SET_FETCHING_APP_SEND_ON_MODERATION',
  FETCH_APP_SEND_ON_MODERATION_ERROR: 'FETCH_APP_SEND_ON_MODERATION_ERROR',

  FETCH_APP_PUBLISH_SUCCESS: 'FETCH_APP_PUBLISH_SUCCESS',
  SET_FETCHING_APP_PUBLISH: 'SET_FETCHING_APP_PUBLISH',
  FETCH_APP_PUBLISH_ERROR: 'FETCH_APP_PUBLISH_ERROR',

  FETCH_APP_CHECK_MODERATION_LIST_SUCCESS: 'FETCH_APP_CHECK_MODERATION_LIST_SUCCESS',
  SET_FETCHING_APP_CHECK_MODERATION_LIST: 'SET_FETCHING_APP_CHECK_MODERATION_LIST',
  FETCH_APP_CHECK_MODERATION_LIST_ERROR: 'FETCH_APP_CHECK_MODERATION_LIST_ERROR',
};

export const screenshotStates = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
};
